$fondo: #151515
@import "~react-image-gallery/styles/scss/image-gallery.scss"
$player: #aca3c1

h1, p, h2, h3, div
  color: #ffffff
body
  background-color: $fondo
.linea
  height: 4px
  border-radius: 5px
  width: 65px
.Peliculas
  background-color: #ffb279
.Series
  background-color: #fce5cd
.Audioymusica
  background-color: #d9d2e9
.Graficayfotografia
  background-color: #caeddc
.Artesescenicas
  background-color: #ffe6f6
.Artesvisuales
  background-color: #b4e3fc
.Escritura
  background-color: #dbe2eb
.home
  background-color: #f0f0f0
  height: 100vh
  display: flex
.himg
  background-color: #f0f0f0
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center
  align-self: center
  align-items: center
  min-height: fit-content
  width: 100%
  img
    margin: 50px 20px
    height: 200px
h1
  font-family: 'gtitulo'
  font-size: 70px
  margin: 23px 0
h2
  font-family: 'centuryg'

p
  font-family: 'centuryg'
  font-size: 18px
.headerp
  text-align: center
  position: absolute
  z-index: 50
  margin: 20px 0
  width: 100%
.colaboradores
  position: absolute
  right: 30px
  max-height: 700px
  height: 100%
  top: 5px
  img
    height: 35px
    cursor: pointer
.peli
  cursor: pointer
  margin-bottom: 40px
  img
    width: 90%
    -webkit-transition: width 0.75s ease, height 0.75s ease
  &:hover
    img
      width: 95%

.ano
  color: #e2e2e2
.isportada
  position: relative
  padding-top: 20px
  padding-left: 50px
  background-position-y: 80%
  p
    max-width: 50%
  h1
    font-weight: 100
  h2
    text-transform: uppercase
.portadacont
  padding-top: 10vw
  h2
    margin-bottom: 5px
.sportada
  height: 80vh
  min-height: 600px
  .slick-slider, .slick-track, .slick-list, .slick-slide
    height: 100%
  .slick-current
    height: 100%
  .slick-current, .slick-slide
    div:first-child
      height: 100%

.sliderpeliculas
  position: relative
  margin: 50px 2%
  h1
    font-family: guasasapo-titulos
    font-size: 52px
  .slick-slide
    box-shadow: none
    border: 0px
    color: none
    background: none
    outline: 0px
.ipt
  min-height: 800px
.nextprev
  display: flex
  position: absolute
  left: 30px
  height: 30px
  top: 5px
  align-self: center
  div
    cursor: pointer
  img
    height: 100%
.btnportada
  display: flex
.ctnportada
  position: relative
  cursor: pointer
  margin-right: 50px
  display: flex
  p
    align-self: center
    font-family: 'centurygl'
    margin: 0
    padding-left: 10px
  img
    height: 30px
  .sharewindow
    top: 40px
    position: absolute
    width: 400px
    height: 400px
*:focus
  outline: 0
  outline: none

.flechita
  position: absolute
  cursor: pointer
  img
    height: 50px
    z-index: 200
.prev
  left: -15px
  top: 50%
  transform: translateY(-50%)
.next
  right: -15px
  top: 50%
  transform: translateY(-50%)
.fep
  top: 64%
.pelidetalles
  display: flex
  min-height: 500px
  .info
    flex: 1 0 40%
    align-self: center
    p
      margin-bottom: 0
    .desc
      padding: 30px 0 0 0
      max-width: 600px
      padding-right: 10%
  .foto
    flex: 0 1 60%
    background-size: cover
    background-position: center
    border-top: 20px solid $fondo
  h1
    font-family: 'gtitulo'
    font-size: 60px
    max-width: 600px
    font-weight: 100
    padding-right: 50px
    line-height: 55px
  h3
    font-family: 'centuryg'
    text-transform: uppercase
    margin: 20px 0 5px 0
.masinfo
  max-width: 600px
  margin: 0 0 35px 0
  display: flex
  justify-content: flex-end
  p
    font-family: "centurygl"!important
    color: #e2e2e2
    width: fit-content
    font-size: 16px
    cursor: pointer
    text-align: right
    font-style: italic
    padding-right: 10%
.fotoinfo
  background-color: rgba(21,21,21,0.5)
  height: 100%
  width: 100%
  display: flex
  opacity: 0
.enter
  opacity: 0
.enter-active
  opacity: 1
  transition: opacity 400ms
.enter-done
  opacity: 0.9
.exit
  opacity: 0.9
.exit-active
  opacity: 0
  transition: opacity 400ms
.fitext
  align-self: center
  padding: 0 10%
  display: flex
  flex-wrap: wrap
  div
    flex-grow: 1
    margin-right: 40px

.fittxt
  p
    font-size: 14px
    font-weight: bold
  .ano
    font-family: centuryg
  .ana
    font-family: centuryg
.xmodal
  font-size: 14px
  position: absolute
  top: 5px
  right: 5px
  padding: 0
  margin: 0
  cursor: pointer
  z-index: 4000

.footer
  border-top: 4px dotted white
  display: flex
  margin: 100px 5% 0 5%
  padding-top: 20px
  justify-content: space-between
  height: 100px
  margin-bottom: 60px
  //.f1, .f2, .f3
  //   height: 100%
  .f1
    width: 25%
    display: flex
    flex-direction: column

    p
      font-family: "centurygl"
      width: 100%
      text-align: left
      font-size: 12px
      margin: 10px 0
      padding: 0
    .logossocial
      text-align: left
      width: 100%
      img
        height: 25px
        margin: 0 10px 0 0
  .f2
    width: 50%
    text-align: center
    justify-content: space-around
    img
      width: 80px
    p
      float: left
      text-align: center
      width: 100%
      font-family: "centurygl"
      font-size: 12px
      a
        margin: 0 10px
  .f3
    display: flex
    width: 25%
    align-items: center
    justify-content: flex-end
    flex-wrap: wrap
    img
      height: 70px
      margin-right: 20px
      align-items: center
.appear
  transform: scale(0)!important
.appear-done
  transform: scale(1)!important
  transition: all .3s ease-in-out

.MuiInputBase-root
  color: white!important
  font-family: centuryg!important
.MuiSelect-select
  border: 2px dotted #e2e2e2!important
  padding: 5px 20px 5px 10px!important
.MuiSelect-icon
  color: white!important
.MuiPaper-root
  color: white!important
  background-color: $fondo!important
  border: 2px dotted white!important
.MuiMenuItem-root
  font-family: centuryg!important
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover
  background-color: #141414!important
.MuiInput-underline:after
  border-bottom: none!important
  content: none!important
  position: unset!important
  transition: none!important
.MuiInput-underline:before
  border-bottom: none!important
  content: none!important
  position: unset!important
  transition: none!important
.cajaepisodio
  h1
    width: 90%
    font-weight: 100
    cursor: pointer
    font-size: 24px
    color: white
    font-family: gtitulo
    b
      font-family: centuryg
  h2
    width: 90%
    font-size: 18px
    color: white
  p
    width: 90%
    color: #e2e2e2
    padding: 0 20px 0 0
    font-family: centurygl
    font-size: 16px
  img
    width: 90%
    cursor: pointer
.sliderepisodios
  height: fit-content!important
.introtv
  height: 100vh
  background-size: cover
  display: flex
.itv
  display: flex
  flex-direction: column
  width: 600px
  margin: 0 auto
  height: 100%
  justify-content: center
  justify-self: center
  align-self: center
  h1
    margin: 0
    padding: 0
  p
    font-family: centurygl
    a
      color: #bf9ba1
      text-decoration: none
  img
    align-self: flex-end
    margin-top: 20px
    width: 30px
.itvb
  border: 25px dashed rgba(98,0,7,0)
  padding: 30px
.imgsliderc
  width: 65vh
  height: fit-content
.modalaudio
  display: flex
  flex-direction: row
  h3
    text-transform: uppercase
    margin: 0 0 0 40px
    font-family: gtitulo

  h2
    font-size: 40px
    text-transform: uppercase
    letter-spacing: 3px
    margin: 0 0 0 40px
    font-family: gtitulo2
    max-width: 400px
  p
    margin-left: 40px
  .canciones
    overflow-y: scroll
    height: 200px
    scrollbar-base-color: red
    -ms-scrollbar-base-color: red
    margin-top: 13px
    p
      font-family: gtitulo
      margin-top: 5px
      margin-bottom: 13px
  .caciones::-webkit-scrollbar
    color: red
audio
  border: none
  box-shadow: none
  caret-color: #151515
  color: #151515
  lighting-color: #151515

.rhap_container
  background-color: #151515!important
  padding: 5px 0 0 0!important
.rhap_time
  color: white!important
.rhap_progress-filled,
.rhap_progress-indicator
  background-color: $player!important
.rhap_main-controls-button,
.rhap_volume-button,
.rhap_repeat-button
  color: $player!important
.rhap_volume-indicator
  background: $player!important
.sec1
  position: relative
  height: fit-content
  align-self: center
.spotiyt
  position: absolute
  bottom: 0px
  left: -1px
  text-align: left
  background-color: rgba(21,21,21,1)
  img
    max-width: 25px
    margin: 5px 5px 0 0

.spr
  right: -1px
  left: unset
  width: fit-content
  img
    margin: 5px
.sp12
  margin-left: 10px
.cursor
  cursor: pointer
.nextet
  position: absolute
  top: 50%
  right: 20px
.colabm
  align-self: center
  a
    text-decoration: none
    color: #981c5e
  p
    padding: 40px
    color: $fondo
    background-color: rgba(174,235,175,0.7)
.sliderpeliculas
  .slick-slider
    margin-left: 30px
.playergaleria
  width: 33.3vw
.zoompdf
  position: absolute
  top: 50%
//   color: black
// .cajapdf
//   position: relative
//   min-width: fit-content
// //   ::-webkit-scrollbar 
// //     width: 5px
//    .containerpdf
//      position: 
//      display: flex
//      align-items: center
//  .pdfrow
//    position: relative!important
//    top: unset!important
.modalintro
  height: unset
  background-image: none
  h1
    font-weight: 100
.cursormodalintro
  position: absolute
  bottom: 20px
  cursor: pointer
